.heading {
  text-align: center;
  font-size: 3rem;
  margin-block: 2rem;
  margin-top: 4rem;
}
.items {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.item {
  width: 20%;
  min-width: 200px;
  max-width: 300px;
  height: 100%;
  background: white;
  border-radius: 1rem;
  overflow: hidden;
  margin-block: 1rem;
  margin-inline: 0.4rem;
  cursor: pointer;
}
.productDetails {
  margin: 0.7rem 0.5rem;
}
.productImg {
  width: 100%;
  aspect-ratio: 3/3;
  object-fit: contain;
}
.rating {
  display: flex;
  margin-block: 0.5rem;
}
.star {
  color: gold;
  margin-right: 0.2rem;
}
.btn button {
  width: 100%;
  height: 35px;
  border: none;
  background: var(--secondary);
  font-size: 1.2rem;
  cursor: pointer;
}
.btn button:hover {
  filter: brightness(0.95);
}
@media screen and (max-width: 786px) {
  .productName {
    font-size: 1rem;
  }
}
@media screen and (max-width: 480px) {
  .item {
    min-width: 100px;
    width: 45%;
  }
}
