:root {
  --primary: #7cdf64;
  --secondary: #ff834f;
  --black: #222222;
  --darkGrey: rgba(34, 34, 34, 80%);
  --grey: rgba(34, 34, 34, 40%);
  --white: rgba(34, 34, 34, 20%);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Dosis', sans-serif;
}
html {
  scroll-behavior: smooth;
}
body {
  color: var(--black);
  background: var(--white);
}
